<template>
  <button
    type="button"
    class="btn btn-info d-flex align-items-center"
    :disabled="isCopied"
    @click="copyToClipboard"
  >
    <template v-if="isCopied">
      <i class="far fa-check-circle"></i>
      <span class="ml-2">Copied To Clipboard</span>
    </template>
    <template v-else>
      <i class="fas fa-share"></i>
      <span class="ml-2">Share Project</span>
    </template>
  </button>
</template>

<script>
export default {
  name: "app-share-link",
  data: function() {
    return {
      isCopied: false
    };
  },
  methods: {
    async copyToClipboard() {
      this.isCopied = true;

      let link = document.location.href;

      if (!navigator.clipboard) this.copyToClipboardFallback(link);
      else await navigator.clipboard.writeText(link);

      setTimeout(() => (this.isCopied = false), 2000);
    },
    copyToClipboardFallback (text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      document.execCommand('copy');
      document.body.removeChild(textArea);
    }
  }
};
</script>
